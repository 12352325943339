import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Settings from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';

import { CustomThemeContext } from '../theme/CustomThemeProvider';
import { useAuth } from '../hooks/useAuth';

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const { currentTheme, setTheme } = React.useContext(CustomThemeContext);
  const popupState = usePopupState({ variant: 'popover', popupId: 'menu' });

  const handleSignOut = () => {
    signOut();
    popupState.close();
  };

  const openProfile = () => {
    navigate('/profile');
    popupState.close();
  };

  // Todo: create separate theme toggle component
  const toggleTheme = () => {
    popupState.close();
    currentTheme === 'dark' ? setTheme('light') : setTheme('dark');
  };

  return (
    <AppBar position='static'>
      <Toolbar>
        <Typography
          variant='h6'
          component='div'
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}>
          Shipping
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            color='inherit'
            {...bindTrigger(popupState)}>
            <AccountCircle />
          </IconButton>
          <Menu
            keepMounted
            id='menu-appbar'
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                '& .MuiAvatar-root': {
                  width: 24,
                  height: 24,
                  mr: 2,
                },
              },
            }}>
            <MenuItem onClick={openProfile}>
              <Avatar /> Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={popupState.close}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              Settings
            </MenuItem>

            <MenuItem onClick={toggleTheme}>
              <ListItemIcon>
                {theme.palette.mode === 'dark' ? <Brightness4Icon /> : <Brightness7Icon />}
              </ListItemIcon>
              {theme.palette.mode === 'dark' ? 'Dark' : 'Light'}
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
