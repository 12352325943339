import { useQuery } from 'react-query';
import { useClient } from '../useClient';

export default function useAccountTypes() {
  const client = useClient();

  const { data, isLoading } = useQuery(
    'accountTypes',
    async () => await client(`api/escrow/accounttypes`),
  );

  return { data: data?.data || null, isLoading };
}
