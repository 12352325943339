import { createTheme } from '@material-ui/core/styles';

export const dark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0097a7',
    },
    secondary: {
      main: '#d84315',
    },
    background: {
      default: '#222',
    },
  },
});

const light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0097a7',
    },
    secondary: {
      main: '#d84315',
    },
  },
});

const themes = {
  dark,
  light,
};

export default function getTheme(theme: string) {
  const keyTyped = theme as keyof typeof themes;
  return themes[keyTyped];
}
