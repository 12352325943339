import { Outlet } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';

import Base from '../../layout/Base';

function LoginHome() {
  return (
    <Base>
      <Fade in={true} mountOnEnter unmountOnExit timeout={500}>
        <Box
          sx={{
            p: { xs: 3 },
            textAlign: 'center',
            m: { sm: '0 auto' },
            mt: { xs: 6, sm: 10 },
            minWidth: { sm: '460px' },
          }}>
          <Typography variant='h2'>Shipping</Typography>
          <Outlet />
        </Box>
      </Fade>
    </Base>
  );
}

export default LoginHome;
