import React from 'react';
import { useParams } from 'react-router-dom';
import { parseJSON, format } from 'date-fns';

import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import TextField from '@material-ui/core/TextField';
import { GridColDef } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/core/Autocomplete';

import { filterRow } from '../../lib/filterRow';
import ProtectedPage from '../../hoc/ProtectedPage';
import StandardLayout from '../../layout/StandardLayout';
import useTransactions from '../../hooks/transactions/useTransactions';

import Table from '../../components/Table';

function AccountAdmin() {
  const { uuid } = useParams();
  const { data, isFetching } = useTransactions(uuid);
  const tableData = data?.data || [];

  const [tableFilters, setTableFilters] = React.useState<string[]>([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);

  React.useEffect(() => {
    filterTable();
  }, [tableFilters, tableData]);

  const filterTable = () => {
    const filterResult = tableFilters
      .map(filter => filter.trim().split(' '))
      .flat()
      .reduce(
        (acc, filter) => acc.filter((row: any) => filterRow(row, filter.toLowerCase())),
        tableData,
      );

    setFilteredTableData(filterResult);
  };

  return (
    <StandardLayout>
      <Stack sx={{ flex: '1 1 0', p: { xs: 1, sm: 2 } }} spacing={2}>
        <Autocomplete
          multiple
          onChange={(event, value) => setTableFilters(value)}
          freeSolo
          filterSelectedOptions
          options={quickFilters}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip label={option} {...getTagProps({ index })} sx={{ lineHeight: '2em' }} />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              label='Filters'
              placeholder='Type a filter, or choose one from the list'
            />
          )}
        />

        <Table
          columns={columnDefinitions}
          data={filteredTableData}
          loading={isFetching}
          sortModel={[
            {
              field: 'created',
              sort: 'asc',
            },
          ]}></Table>
      </Stack>
    </StandardLayout>
  );
}

const columnDefinitions: GridColDef[] = [
  { field: 'accountId', headerName: 'Account number', width: 170 },
  {
    width: 160,
    field: 'created',
    headerName: 'Created',
    valueGetter: params => {
      const values = params.value as any;
      return format(parseJSON(values), 'yyyy-MM-dd HH:mm:ss');
    },
  },
  { field: 'amount', headerName: 'Amount', width: 150 },
  { field: 'closingBalance', headerName: 'Closing balance', width: 190 },
  { field: 'reference', headerName: 'Reference', width: 190 },
  { field: 'requestGuid', headerName: 'Request Guid', width: 300 },
  { field: 'guid', headerName: 'Guid', width: 300 },
  { field: 'sequenceId', headerName: 'Sequence', width: 150 },
  { field: 'transactionDefinitionId', headerName: 'Transaction definition', width: 230 },
  { field: 'id', headerName: 'ID', width: 170 },
  { field: 'exchangeRate', headerName: 'Exchange rate', width: 180 },
  { field: 'status', headerName: 'Status', width: 150 },
  {
    width: 160,
    field: 'updated',
    headerName: 'updated',
    valueGetter: params => {
      const values = params.value as any;
      return format(parseJSON(values), 'yyyy-MM-dd HH:mm:ss');
    },
  },
];

const quickFilters = ['No idea what should be here'];

export default ProtectedPage(AccountAdmin);
