import logo from '../logo.svg';

function About() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>All them ships and such</p>
      </header>
    </div>
  );
}

export default About;
