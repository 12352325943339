import { useQuery } from 'react-query';
import { useClient } from '../useClient';

export default function useAccounts() {
  const client = useClient();

  const { data, isLoading } = useQuery(
    'accounts',
    async () =>
      await client(
        `api/escrow/accounts?includeLastTransaction=true&includeCurrency=true&pageSize=10000`,
      ),
  );

  const accounts = data?.data || null;

  return { data: accounts, isLoading };
}
