import React from 'react';
import { parseJSON, format } from 'date-fns';

import useAccounts from './useAccounts';
import useAccountTypes from './useAccountTypes';
import useAccountGroups from './useAccountGroups';
import useAccountStatus from './useAccountStatus';

export default function useMappedAccounts() {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const accounts = useAccounts();
  const types = useAccountTypes();
  const status = useAccountStatus();
  // const groups = useAccountGroups();

  const fetching = accounts.isLoading || types.isLoading || status.isLoading;

  if (!fetching && !data.length) {
    const mapType = (typeId: number): string => {
      const matchedType = types.data.find((type: any) => type.id === typeId);
      return matchedType?.name || 'Not found';
    };
    const mapStatus = (statusId: number): string => {
      const matchedStatus = status.data.find((status: any) => status.id === statusId);
      return matchedStatus?.name || 'Not found';
    };
    const mapGlobal = (globalId: any): string => {
      const matchedGlobal = accounts.data.find((account: any) => account.id === globalId);
      return matchedGlobal?.name || 'Not found';
    };
    const parseTime = (time: any): string => {
      return format(parseJSON(time), 'yyyy-MM-dd HH:mm:ss');
    };

    const mappedAccounts = accounts.data.map((account: any) => ({
      ...account,
      _status: mapStatus(account.status),
      _updated: parseTime(account.updated),
      _created: parseTime(account.created),
      _accountType: mapType(account.accountTypeId),
      _globalAccount: mapGlobal(account.globalAccountId),
    }));

    setIsLoading(false);
    setData(mappedAccounts);
  }

  return { data, isLoading };
}
