import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Chip from '@material-ui/core/Chip';
import Stack from '@material-ui/core/Stack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';
import { GridColDef, GridCellParams } from '@material-ui/data-grid';

import { filterRow } from '../../lib/filterRow';
import ProtectedPage from '../../hoc/ProtectedPage';
import useMappedAccounts from '../../hooks/accounts/useMappedAccounts';

import Table from '../../components/Table';

const filterTable = (data: any[], filters: string[]) => {
  return filters
    .map(filter => filter.trim())
    .flat()
    .reduce((acc, filter) => acc.filter((row: any) => filterRow(row, filter.toLowerCase())), data);
};

function AccountsAdmin() {
  const [tableFilters, setTableFilters] = React.useState<string[]>([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  // Todo: error
  const { isLoading, data: accounts } = useMappedAccounts();

  React.useEffect(() => {
    if (accounts.length) {
      setFilteredTableData(filterTable(accounts, tableFilters));
    }
  }, [tableFilters, accounts]);

  return (
    <Stack sx={{ flex: '1 1 0', p: { xs: 1, sm: 2 } }} spacing={2}>
      <Autocomplete
        multiple
        onChange={(event, value) => setTableFilters(value)}
        freeSolo
        filterSelectedOptions
        options={quickFilters}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip label={option} {...getTagProps({ index })} sx={{ lineHeight: '2em' }} />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            label='Filters'
            placeholder='Type a filter, or choose one from the list'
          />
        )}
      />

      <Table
        loading={isLoading}
        data={filteredTableData}
        columns={columnDefinitions}
        sortModel={[
          {
            field: 'id',
            sort: 'asc',
          },
        ]}></Table>
    </Stack>
  );
}

const columnDefinitions: GridColDef[] = [
  {
    width: 110,
    field: 'openAccount',
    headerName: 'Acc',
    renderCell: (params: GridCellParams) => {
      const accGuid = params.row.guid;
      return (
        <Button
          size='small'
          color='primary'
          variant='outlined'
          component={RouterLink}
          to={`/accounts/${accGuid}`}>
          Open
        </Button>
      );
    },
  },
  { field: 'id', headerName: 'Account number', width: 170 },
  { field: 'entityId', headerName: 'Entity', width: 300 },
  { field: '_accountType', headerName: 'Account type', width: 170 },
  { field: 'name', headerName: 'Accout name', width: 170 },
  {
    width: 120,
    sortable: false,
    field: 'currency',
    headerName: 'Currency',
    valueGetter: params => {
      const values = params.value as any;
      return values.isoCode;
    },
  },
  {
    width: 120,
    sortable: false,
    field: 'lastTransaction',
    headerName: 'Balance',
    valueGetter: params => {
      const values = params.value as any;
      return values.closingBalance;
    },
  },
  { field: '_status', headerName: 'Account status', width: 190 },
  { field: 'accountGroupId', headerName: 'Group', width: 190 },
  { field: 'vendorId', headerName: 'vendorId', width: 150 },
  { field: '_globalAccount', headerName: 'Global Account', width: 190 },
  { field: 'branchCode', headerName: 'branchCode', width: 170 },
  { field: 'accountNo01', headerName: 'accountNo01', width: 170 },
  { field: 'accountNo02', headerName: 'accountNo02', width: 170 },
  { field: 'accountNo03', headerName: 'accountNo03', width: 170 },
  { field: '_created', headerName: 'created', width: 160 },
  { field: '_updated', headerName: 'updated', width: 160 },
];

const quickFilters = [
  'General',
  'Escrow',
  'Clearing',
  'Global',
  'Something else',
  'Maybe your most searched here',
];

export default ProtectedPage(AccountsAdmin);
