import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuth } from '../../hooks/useAuth';

function Login() {
  const { signIn, loading } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const login = (event: any) => {
    event.preventDefault();
    signIn({ username, password })
      .then(() => navigate('/'))
      .catch(error => console.error(error));
  };

  if (loading) {
    return <CircularProgress sx={{ mt: 12 }} color='primary' />;
  }

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Box sx={{ pt: { xs: 8 } }}>
        <form onSubmit={login}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type='text'
              label='Username'
              value={username}
              variant='outlined'
              onChange={event => setUsername(event.target.value)}
            />

            <TextField
              fullWidth
              type='password'
              value={password}
              label='Password'
              variant='outlined'
              onChange={event => setPassword(event.target.value)}
            />

            <Button variant='contained' fullWidth onClick={login} type='submit'>
              Login
            </Button>
            <Button variant='outlined' fullWidth>
              Forgot Password
            </Button>
          </Stack>
        </form>
      </Box>
    </Fade>
  );
}

export default Login;
