import React from 'react';
import { DataGrid, GridColDef, GridSortModel } from '@material-ui/data-grid';

export default function Table({
  data,
  columns,
  sortModel,
  loading = false,
}: {
  data: object[];
  loading?: boolean;
  columns: GridColDef[];
  sortModel: GridSortModel;
}) {
  return (
    <DataGrid
      rows={data}
      autoPageSize
      pageSize={15}
      rowHeight={60}
      loading={loading}
      columns={columns}
      columnBuffer={12}
      density={'compact'}
      sortModel={sortModel}
      disableSelectionOnClick
    />
  );
}
