import { useQuery } from 'react-query';
import { useClient } from '../useClient';

export default function useProfile(userGuid: string) {
  const client = useClient();

  return useQuery(
    'profile',
    async () => await client(`api/entities/persons/cadfe5b1-eaf1-11eb-ba4a-0242ac160002`),
  );
  //   return useQuery('profile', async () => await client(`api/entities/persons/${userGuid}`));
}
