import { useState, useContext, createContext } from 'react';

import { useClient } from './useClient';
import { useLocalStorage } from './useLocalStorage';

const authContext = createContext({});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext) as ReturnType<typeof useProvideAuth>;
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const client = useClient();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useLocalStorage('__shipping_token__', null);
  const [userGuid, setUserGuid] = useLocalStorage('__user_guid__', null);
  const signedIn = !!userGuid;

  const signIn = async ({ username, password }: { username: string; password: string }) => {
    try {
      setLoading(true);

      const { userGuid }: { userGuid: string } = await client('public/securitycontrol/auth/pass', {
        body: { username, password, applicationGuid: window._env_.REACT_APP_APPLICATION_GUID },
      });

      setUserGuid(userGuid);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const signOut = () => {
    setToken(null);
    setUserGuid(null);
  };

  const sendPasswordResetEmail = (email: string) => {
    return 'hello';
  };

  const confirmPasswordReset = (code: string, password: string) => {
    return 'hello';
  };
  // Return the user object and auth methods
  return {
    error,
    signIn,
    loading,
    signOut,
    signedIn,
    userGuid,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}
