import React from 'react';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import Base from '../layout/Base';
import { useAuth } from '../hooks/useAuth';

/**
 * Wrap any component with this HOC to get a `isAuthenticated` boolean, `user`
 * object, `role` string and `userId` string made available on the props of the wrapped component.
 * @param {{React.FC<AuthWrapperProps & { WrappedComponent: React.ReactType; }>}} WrappedComponent
 * @returns {{React.ComponentType<{ isAuthenticated: boolean; user: object; userId: string; }>}}
 */
const LoginProtection = ({ WrappedComponent, ...otherProps }: any): React.ReactElement => {
  const { signedIn } = useAuth();

  let navigate = useNavigate();

  React.useEffect(() => {
    if (!signedIn) {
      navigate('/auth');
    }
  }, [signedIn, navigate]);

  // if (authStatus === 'pending') {
  //   return (
  //     <Base>
  //       <CircularProgress sx={{ m: 'auto', mt: 12 }} color='primary' />
  //     </Base>
  //   );
  // }

  return <WrappedComponent {...otherProps} />;
};

const IsProtectedPage = (WrappedComponent: any) => {
  return (props: any) => {
    return <LoginProtection WrappedComponent={WrappedComponent} {...props} />;
  };
};

export default IsProtectedPage;
