import { useTheme } from '@material-ui/core/styles';

import Styled from './styles';

const Base = ({ children }: any) => {
  const theme = useTheme();

  return <Styled.Wrap theme={theme}>{children}</Styled.Wrap>;
};

export default Base;
