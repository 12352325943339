import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import ProtectedPage from '../../hoc/ProtectedPage';

function Accounts() {
  return (
    <Fade in={true} unmountOnExit timeout={500}>
      <Box
        sx={{
          p: 1,
          flex: '1 1 0',
          overflow: 'auto',
        }}></Box>
    </Fade>
  );
}

export default ProtectedPage(Accounts);
