import Box from '@material-ui/core/Box';
import Stack from '@material-ui/core/Stack';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAuth } from '../../hooks/useAuth';
import ProtectedPage from '../../hoc/ProtectedPage';
import useProfile from '../../hooks/profile/useProfile';
import StandardLayout from '../../layout/StandardLayout';

function Profile() {
  const { userGuid } = useAuth();
  const { data, isLoading } = useProfile(userGuid);
  const profile = data?.data || {};

  if (isLoading) {
    return (
      <StandardLayout>
        <Box
          sx={{
            p: { xs: 3 },
            textAlign: 'center',
            m: { sm: '0 auto' },
            minWidth: { sm: '320px' },
          }}>
          <Typography variant='h2' gutterBottom>
            Profile
          </Typography>
          <CircularProgress sx={{ mt: 12 }} color='primary' />
        </Box>
      </StandardLayout>
    );
  }

  return (
    <StandardLayout>
      {/* Todo: scroll  */}
      <Box
        sx={{
          p: { xs: 2 },
          textAlign: 'center',
          m: { sm: '0 auto' },
          minWidth: { sm: '320px' },
        }}>
        <Typography variant='h2' gutterBottom>
          Profile
        </Typography>

        <Stack spacing={3}>
          <TextField
            disabled
            fullWidth
            type='text'
            label='First names'
            value={profile.firstNames}
            variant='outlined'
          />

          <TextField
            disabled
            fullWidth
            type='text'
            label='Last name'
            value={profile.lastName}
            variant='outlined'
          />

          <TextField
            disabled
            fullWidth
            type='text'
            label='Known As'
            value={profile.knownAs}
            variant='outlined'
          />

          <TextField
            disabled
            fullWidth
            type='text'
            label='Contact Number'
            value={profile.contactNumber}
            variant='outlined'
          />

          <TextField
            disabled
            fullWidth
            type='email'
            label='Email'
            value={profile.email}
            variant='outlined'
          />

          <TextField
            disabled
            fullWidth
            type='text'
            label='Updated'
            value={profile.updated}
            variant='outlined'
          />
        </Stack>
      </Box>
    </StandardLayout>
  );
}

export default ProtectedPage(Profile);
