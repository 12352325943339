import { useQuery } from 'react-query';
import { useClient } from '../useClient';

export default function useTransactions(accountGuid: string) {
  const client = useClient();

  return useQuery(
    'transactions',
    async () => await client(`api/escrow/transactions?pageSize=100000&accountGuid=${accountGuid}`),
  );
}
