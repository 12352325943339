import { useAuth } from './useAuth';
import { useLocalStorage } from './useLocalStorage';

export function useClient() {
  const { signOut } = useAuth();
  const [token, setToken] = useLocalStorage('__shipping_token__', null);

  return (endpoint: string, { body, ...customConfig }: any = {}) => {
    const headers = { 'content-type': 'application/json' } as any;

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    } as any;

    if (body) {
      config.body = JSON.stringify(body);
    }

    return window
      .fetch(`${window._env_.REACT_APP_HOST}/${endpoint}`, config)
      .then(async response => {
        if (response.status === 401) {
          signOut();
          return;
        }
        if (response.ok) {
          const newToken = response.headers.get('Token');
          if (token !== newToken) {
            setToken(newToken);
          }

          return await response.json();
        } else {
          const errorMessage = await response.text();
          return Promise.reject(new Error(errorMessage));
        }
      });
  };
}
