import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import Base from './Base';
import Header from '../components/Header';

const StandardLayout = ({ children, hideHeader = false }: any) => {
  return (
    <Base>
      <Slide appear={false} in={!hideHeader} direction='down'>
        <Box>
          <Header />
        </Box>
      </Slide>

      <Fade in={true} unmountOnExit timeout={500}>
        <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {children}
        </Box>
      </Fade>
    </Base>
  );
};

export default StandardLayout;
