import ProtectedPage from '../hoc/ProtectedPage';
import StandardLayout from '../layout/StandardLayout';

import AccountsAdmin from './accounts/AccountsAdmin';

function Home() {
  return (
    <StandardLayout>
      <AccountsAdmin />
    </StandardLayout>
  );
}

export default ProtectedPage(Home);
