import { useQuery } from 'react-query';
import { useClient } from '../useClient';

export default function useAccountStatus() {
  const client = useClient();

  const { data, isLoading } = useQuery(
    'accountStatus',
    async () => await client(`api/escrow/accountstatus`),
  );

  return { data: data?.data || null, isLoading };
}
