import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Alert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import Home from './pages/Home';
import About from './pages/About';
import LoginHome from './pages/auth';
import Profile from './pages/profile';
import Login from './pages/auth/Login';

import Accounts from './pages/accounts';
import AccountAdmin from './pages/accounts/AccountAdmin';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [sWRegistration, setSwRegistration] = React.useState(null);

  React.useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: registration => {
        setShowUpdate(true);
        setSwRegistration(registration);
      },
    });
  }, []);

  const updateApp = () => {
    if (sWRegistration && sWRegistration.waiting) {
      sWRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
      sWRegistration.waiting.addEventListener(
        'statechange',
        (event: { target: { state: string } }) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        },
      );
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className='App'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='profile' element={<Profile />} />

          <Route path='accounts' element={<Accounts />}>
            {/* <Route path='create' element={<CreateListing />} /> */}
          </Route>
          <Route path='accounts/:uuid' element={<AccountAdmin />} />

          <Route path='auth' element={<LoginHome />}>
            <Route path='/' element={<Login />} />
          </Route>

          {/* Todo: 404 not found page */}
          <Route path='/*' element={<LoginHome />} />
        </Routes>

        {/* Todo: Move to a component */}
        <Snackbar
          open={showUpdate}
          onClose={() => setShowUpdate(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Alert
            elevation={4}
            severity='info'
            variant='filled'
            sx={{ width: '100%' }}
            onClose={() => setShowUpdate(false)}
            action={
              <>
                <Button size='small' variant='text' onClick={updateApp}>
                  Update
                </Button>
                <IconButton
                  size='small'
                  color='inherit'
                  aria-label='close'
                  onClick={() => setShowUpdate(false)}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </>
            }>
            An update is available
          </Alert>
        </Snackbar>
      </div>
    </QueryClientProvider>
  );
}

export default App;
