export const filterRow = (row: any, filter: string) => {
  let wasMatch = false;

  Object.values(row).forEach(value => {
    if (typeof value === 'number' && value.toString().toLowerCase().includes(filter)) {
      wasMatch = true;
    }

    if (typeof value === 'string' && value.toLowerCase().includes(filter)) {
      wasMatch = true;
    }

    if (typeof value === 'object' && Array.isArray(value)) {
      const matched = value.filter(item =>
        typeof item === 'string' ? item.toLowerCase().includes(filter) : false,
      );

      if (matched.length) {
        wasMatch = true;
      }
    }
  });

  return wasMatch;
};
